<template>
  <article class="media">
    <!-- <form @submit.prevent="submitForm"> -->
    <div class="media-content">
      <div class="field">
        <p class="control">
          <textarea
            class="textarea"
            :placeholder="$t('addCommentPlaceholder')"
            v-model="commentText"
          ></textarea>
        </p>
      </div>
      <div class="field">
        <p class="control">
          <button class="button" @click="submitForm">
            <span v-if="props.isReply">{{ $t("replyBtn") }}</span
            ><span v-else>{{ $t("postCommentBtn") }}</span>
          </button>
        </p>
      </div>
    </div>
    <!-- <div class="field">
        <label class="label">Write a comment</label>
        <input
          v-model="commentText"
          class="input"
          type="text"
          placeholder="Enter your comment here"
          required
        />
      </div> -->
    <!-- <button class="button is-primary" type="submit">Comment</button> -->
    <!-- </form> -->
  </article>
</template>
  

<script>
import { computed, ref } from "vue";
import { db } from "@/firebase/config";
import { collection, addDoc, doc, serverTimestamp } from "firebase/firestore";
import { useStore } from "vuex";

export default {
  props: {
    docId: {
      type: String,
      required: true,
    },
    commentId: {
      type: String,
      required: false,
    },
    isReply: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const commentText = ref(""); // To hold the input value
    const store = useStore();

    const submitForm = async () => {
      try {
        let targetCollectionRef;
        if (props.isReply) {
          // If it's a reply, target the Replies sub-collection of the comment
          targetCollectionRef = collection(
            db,
            "Posts",
            props.docId,
            "Comments",
            props.commentId,
            "Replies"
          );
        } else {
          // If it's a comment, target the Comments collection of the post
          targetCollectionRef = collection(
            db,
            "Posts",
            props.docId,
            "Comments"
          );
        }

        // Adding a new document to the Comments collection
        await addDoc(targetCollectionRef, {
          text: commentText.value,
          createdAt: serverTimestamp(),
          createdBy: store.state.userProfile.display_name,
          userUid: store.state.user.uid,
        });

        commentText.value = ""; // Clear the input field after submission
      } catch (err) {
        console.error("Error adding document: ", err);
      }
    };

    return { commentText, submitForm, props };
  },
};
</script>


<style>
</style>