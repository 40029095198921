<template>
  <div class="container">
    <div class="columns is-centered">
      <!-- Centered column, adjust the size as needed -->
      <div class="column is-4">
        <form @submit.prevent="login">
          <div class="field">
            <div class="control">
              <input
                class="input"
                type="email"
                v-model="email"
                required
                :placeholder="$t('emailLabel')"
              />
            </div>
          </div>

          <div class="field">
            <div class="control">
              <input
                class="input"
                type="password"
                v-model="password"
                required
                :placeholder="$t('password')"
              />
            </div>
          </div>

          <div class="field mb-5">
            <button
              :class="{ 'is-loading': isLoading }"
              type="submit"
              class="button is-primary is-rounded is-fullwidth"
            >
              {{ $t("login") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
  

<script>
import { ref } from "vue";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "@/firebase/config"; // Adjust the import according to your project structure

export default {
  setup() {
    const email = ref("");
    const password = ref("");
    const isLoading = ref(false); // Loading state for the button

    const login = async () => {
      try {
        isLoading.value = true; // Set loading to true when login starts
        await signInWithEmailAndPassword(auth, email.value, password.value);
        // You can navigate the user to another page if login is successful
      } catch (error) {
        console.error("Error logging in: ", error);
      } finally {
        isLoading.value = false; // Set loading to false when login is complete
      }
    };

    return {
      email,
      password,
      login,
      isLoading, // Return isLoading ref
    };
  },
};
</script>

  

<style scoped>
</style>
