<template>
  <section class="section">
    <!-- Loading Animation -->
    <div v-if="isLoading" class="loading-container">
      <button class="button is-loading">Loading...</button>
    </div>
    <div class="container" v-else>
      <div class="">
        <div class="columns">
          <div class="column mt-5">
            <!-- <h1 class="title">Blog Posts</h1> -->
            <router-link
              to="/create-post"
              class="button is-black is-rounded mb-5"
              v-if="isAuthenticated && userProfile.role == 'AUTHOR'"
              >{{ $t("createBtn") }}</router-link
            >

            <div class="field">
              <!-- <b>Search a post</b> -->
              <input
                class="input is-black is-rounded is-normal"
                type="text"
                v-model="searchTitle"
                :placeholder="$t('searchPlaceholder')"
              />
            </div>
            <div v-for="post in posts" :key="post.id" class="post-container">
              <BlogPost :post="post" />
            </div>
            <!-- <div class="mt-5">
              <p>Tags</p>
              <div v-if="selectedTag">
                <span
                  class="tag is-primary is-clickable is-rounded is-small"
                  @click="deselectTag"
                  ><span class="icon">
                    <i class="fas fa-tag"></i>
                  </span>
                  {{ selectedTag }} <button class="delete"></button
                ></span>
              </div>
              <div
                v-for="tag in uniqueTags"
                :key="tag"
                @click="selectTag(tag)"
                class="tag is-info is-clickable is-rounded is-small"
              >
                <span class="icon">
                  <i class="fas fa-tag"></i>
                </span>
                {{ tag }}
              </div>
            </div>-->
          </div>
          <!-- <div class="columnn is-three-quarters"></div> -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { computed, onMounted, ref, watchEffect } from "vue";
import { db } from "@/firebase/config";
import BlogPost from "@/components/BlogPostView.vue";
import { collection, getDocs } from "firebase/firestore";
import { useStore } from "vuex";

export default {
  components: {
    BlogPost,
  },
  setup() {
    const posts = ref([]);
    const searchTitle = ref("");
    const store = useStore();
    const selectedTag = ref("");
    const filteredPosts = ref([]);
    const isLoading = ref(true);

    onMounted(async () => {
      const querySnapshot = await getDocs(collection(db, "Posts"));
      const fetchedPosts = [];
      querySnapshot.forEach((doc) => {
        //console.log("Fetched post:", doc.data()); // Debugging line
        fetchedPosts.push({ id: doc.id, ...doc.data() });
      });

      // Sorting and removing duplicates
      posts.value = fetchedPosts.sort((a, b) => b.createdAt - a.createdAt);
      //console.log("All posts:", posts.value); // Debugging line
      isLoading.value = false;
    });

    // Watch for changes in searchTitle and selectedTag to update filteredPosts
    watchEffect(() => {
      filteredPosts.value = posts.value.filter((post) => {
        return (
          searchTitle.value === "" ||
          post.title.toLowerCase().includes(searchTitle.value.toLowerCase())
        );
      });
    });

    // Unique tags without duplicates
    // const uniqueTags = computed(() => {
    //   const allTags = posts.value.flatMap((post) => post.tags);
    //   return [...new Set(allTags)];
    // });

    // const selectTag = (tag) => {
    //   selectedTag.value = tag;
    // };

    // const deselectTag = () => {
    //   selectedTag.value = "";
    // };

    return {
      posts: filteredPosts,
      // uniqueTags,
      searchTitle,
      // selectedTag,
      // selectTag,
      // deselectTag,
      userProfile: computed(() => store.state.userProfile),
      isAuthenticated: computed(() => store.getters.isAuthenticated),
      isLoading,
    };
  },
};
</script>

<style scoped>
.title {
  color: #4a4a4a;
}

.button.is-primary {
  width: 200px;
  margin-bottom: 20px;
}

.box {
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
}

.tag.is-clickable {
  cursor: pointer;
  margin-right: 5px;
  margin-bottom: 5px;
}

.tag.is-info.is-rounded {
  margin-right: 0.5rem;
  display: inline-flex;
  align-items: center;
}
.tag.is-info.is-rounded .icon {
  margin-right: 0.25rem;
}

.post-container {
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.post-container:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}
</style>