<template>
  <div class="box" :class="{ 'is-rtl': isRtl }">
    <!-- Edit and Delete Buttons for Authenticated Users -->
    <div
      v-if="isAuthenticated && userProfile.role == 'AUTHOR'"
      class="buttons is-right"
    >
      <router-link
        :to="{ name: 'editPost', params: { docId: post.id } }"
        class="button is-warning"
      >
        {{ $t("editBtn") }}
      </router-link>
      <button @click="deletePost" class="button is-danger">
        {{ $t("deleteBtn") }}
      </button>
    </div>
    <div class="columns">
      <div class="column is-one-third">
        <figure class="image is-4by3">
          <img :src="post.imageUrl" alt="Blog Image" v-if="post.imageUrl" />
          <img src="../assets/missingImage.jpeg" alt="" v-else />
        </figure>
      </div>
      <router-link
        :to="{
          name: 'PostView',
          params: { slug: post.title, docId: post.id },
        }"
        class="no-link-color"
      >
        <div class="column">
          <h1 class="title">{{ post.title }}</h1>
          <p class="subtitle mt-2">{{ post.description }}</p>
          <p class="author">
            <b>{{ $t("authorLabel") }}</b> {{ post.author }}
          </p>
          <p class="date">
            <b>{{ $t("createdOnLabel") }}</b> {{ formattedDate }}
          </p>
          <!-- <div class="tags mt-2">
            <span
              v-for="tag in post.tags"
              :key="tag"
              class="tag is-info is-rounded is-small"
            >
              <span class="icon">
                <i class="fas fa-tag"></i>
              </span>
              {{ tag }}
            </span>
          </div> -->
        </div>
      </router-link>
    </div>
  </div>
</template>
  
  <script>
import {
  addDoc,
  doc,
  getDoc,
  collection,
  deleteDoc,
  serverTimestamp,
} from "firebase/firestore";
import { computed } from "vue";
import { useStore } from "vuex";
import { db } from "@/firebase/config";

export default {
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const formattedDate = computed(() => {
      const date = new Date(props.post.createdAt.seconds * 1000);
      return date.toLocaleDateString();
    });

    const isRtl = computed(() => {
      return props.post.lang === "AR";
    });

    const deletePost = async () => {
      try {
        const postRef = doc(db, "Posts", props.post.id);
        const postDoc = await getDoc(postRef);

        if (postDoc.exists()) {
          //Move to DeletedPosts CollectionReference
          await addDoc(collection(db, "DeletedPosts"), {
            ...postDoc.data(),
            deletedAt: serverTimestamp(),
          });

          // Delete from Posts collection
          await deleteDoc(postRef);

          window.location.reload();
        }
      } catch (error) {
        //console.error("Error deleting post:", error);
      }
    };

    return {
      formattedDate,
      isAuthenticated: computed(() => store.getters.isAuthenticated),
      userProfile: computed(() => store.state.userProfile),
      deletePost,
      isRtl,
    };
  },
};
</script>
  
<style>
.no-link-color {
  color: inherit; /* This will ensure the link color is same as parent's color */
  text-decoration: none; /* Removing the default underline */
}

.no-link-color:hover {
  color: inherit;
  text-decoration: none; /* Underline on hover to indicate it's a clickable link */
}

.is-rtl {
  direction: rtl;
  text-align: right;
}
</style>