<template>
  <div class="container">
    <div class="columns is-centered">
      <div class="column is-4">
        <form @submit.prevent="signUp">
          <div class="field">
            <div class="control">
              <input
                class="input"
                type="email"
                v-model="email"
                required
                placeholder="Email"
              />
            </div>
          </div>

          <div class="field">
            <div class="control">
              <input
                class="input"
                type="password"
                v-model="password"
                required
                placeholder="Password"
              />
            </div>
          </div>

          <div class="field">
            <div class="control">
              <input
                class="input"
                type="text"
                v-model="displayName"
                required
                placeholder="Display name"
              />
            </div>
          </div>

          <div class="field mb-5">
            <button
              :class="{ 'is-loading': isLoading }"
              type="submit"
              class="button is-primary is-rounded is-fullwidth"
            >
              Sign Up
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { ref } from "vue";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { auth, db } from "@/firebase/config"; // Adjust the import according to your project structure

export default {
  setup() {
    const email = ref("");
    const password = ref("");
    const displayName = ref("");
    const isLoading = ref(false); // Loading state for the button

    const signUp = async () => {
      try {
        isLoading.value = true; // Set loading to true when login starts
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email.value,
          password.value
        );
        const user = userCredential.user;

        await setDoc(doc(db, "Users", user.uid), {
          display_name: displayName.value,
          email: email.value,
          role: "VISITOR",
          first_name: "", // You may want to add a field for this in your form
          last_name: "", // You may want to add a field for this in your form
          photo_url: "", // You may want to add a field for this in your form
        });
      } catch (error) {
        console.error("Error signing up: ", error);
      } finally {
        isLoading.value = false; // Set loading to false when login is complete
      }
    };

    return {
      email,
      password,
      displayName,
      signUp,
      isLoading,
    };
  },
};
</script>

<style scoped>
</style>
