<template>
  <footer class="footer">
    <div class="content has-text-centered">
      <p>
        <!-- Developed by o3shx Inc. <br /> -->
        &copy; {{ $t("footerText") }}
      </p>
      <p>{{ versionNumner }}</p>
    </div>
  </footer>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();
    return { versionNumner: computed(() => store.state.versionNumber) };
  },
};
</script>

  <style scoped>
/* Optional: Add custom styling here if needed */
</style>
  