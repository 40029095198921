<template>
  <div v-if="replies.length">
    <article v-for="reply in replies" :key="reply.id" class="media">
      <div class="media-content">
        <div class="content">
          <p>
            <strong>{{ reply.createdBy }}</strong>
            <br />
            {{ reply.text }}
            <br />
            <small class="has-text-grey"
              ><a
                v-if="
                  isAuthenticated &&
                  (userUid === reply.userUid || userRole == 'AUTHOR')
                "
                @click="deleteReply(reply.id)"
                >{{ $t("deleteLabel") }} </a
              >{{ getDuration(reply.createdAt) }}</small
            >
          </p>
        </div>
      </div>
    </article>
  </div>
</template>
  
  <script>
import { computed, onMounted, ref } from "vue";
import { db } from "@/firebase/config";
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { useStore } from "vuex";

export default {
  props: {
    postDocId: {
      type: String,
      required: true,
    },
    commentDocId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const replies = ref([]);
    const store = useStore();
    // Computed properties for user authentication and userUid
    const isAuthenticated = computed(() => store.getters.isAuthenticated);
    const userUid = computed(() => store.state.user.uid);
    const userRole = computed(() => store.state.userProfile.role);

    onMounted(() => {
      const repliesRef = query(
        collection(
          db,
          "Posts",
          props.postDocId,
          "Comments",
          props.commentDocId,
          "Replies"
        ),
        orderBy("createdAt", "asc")
      );

      const unsubscribe = onSnapshot(repliesRef, (snapshot) => {
        replies.value = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
      });

      return () => {
        unsubscribe();
      };
    });

    const getDuration = (timestamp) => {
      if (!timestamp || !timestamp.toDate) {
        console.error("Invalid timestamp: ", timestamp);
        return "Invalid timestamp";
      }

      const now = new Date();
      const timeDiff = now - timestamp.toDate();
      const seconds = Math.floor(timeDiff / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);

      if (days > 0) return `${days} days ago`;
      if (hours > 0) return `${hours} hours ago`;
      if (minutes > 0) return `${minutes} minutes ago`;
      return "Just now";
    };

    const deleteReply = async (replyId) => {
      try {
        await deleteDoc(
          doc(
            db,
            "Posts",
            props.postDocId,
            "Comments",
            props.commentDocId,
            "Replies",
            replyId
          )
        );
      } catch (error) {
        console.error("Error deleting reply: ", error);
      }
    };

    return {
      replies,
      getDuration,
      isAuthenticated,
      userUid,
      userRole,
      deleteReply,
    };
  },
};
</script>
  