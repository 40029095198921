<template>
  <div>
    <div v-if="comments.length === 0" class="notification is-warning">
      {{ $t("noCommentsLabel") }}
    </div>
    <div v-else>
      <article v-for="comment in comments" :key="comment.id" class="media">
        <div class="media-content">
          <div class="content">
            <p>
              <strong>{{ comment.createdBy }}</strong>

              <br />
              {{ comment.text }}
              <br />
              <small>
                <a v-if="isAuthenticated" @click="startReply(comment.id)"
                  >{{ $t("replyLabel") }}
                </a>
                <a
                  v-if="
                    isAuthenticated &&
                    (userUid === comment.userUid || userRole == 'AUTHOR')
                  "
                  @click="deleteComment(comment.id)"
                >
                  {{ $t("deleteLabel") }}
                </a>
                <span class="has-text-grey">{{
                  getDuration(comment.createdAt)
                }}</span>
              </small>
            </p>
            <replies-view
              :postDocId="docId"
              :commentDocId="comment.id"
              style="margin-left: 50px"
            />
            <div v-if="replyTo === comment.id">
              <create-comment
                :docId="docId"
                :isReply="true"
                :commentId="comment.id"
                style="margin-left: 50px"
              />
            </div>
          </div>
          <div class="buttons" v-if="isAuthenticated"></div>
        </div>
      </article>
    </div>
  </div>
</template>
  
  
  
  

<script>
import { onMounted, ref, computed } from "vue";
import { useStore } from "vuex";
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "@/firebase/config";
import CreateComment from "@/components/CreateComment.vue";
import RepliesView from "./RepliesView.vue";

export default {
  components: { CreateComment, RepliesView },

  props: {
    docId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const comments = ref([]);

    // Computed properties for user authentication and userUid
    const isAuthenticated = computed(() => store.getters.isAuthenticated);
    const userUid = computed(() => store.state.user.uid);
    const userRole = computed(() => store.state.userProfile.role);

    const replyTo = ref(null);

    const startReply = (commentId) => {
      replyTo.value = commentId;
    };

    onMounted(() => {
      const commentsRef = query(
        collection(db, "Posts", props.docId, "Comments"),
        orderBy("createdAt", "desc")
      );

      const unsubscribe = onSnapshot(commentsRef, (snapshot) => {
        comments.value = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
      });

      // Return the unsubscribe function to stop listening to changes
      // when the component is unmounted
      return () => {
        unsubscribe();
      };
    });

    const getDuration = (timestamp) => {
      if (!timestamp || !timestamp.toDate) {
        console.error("Invalid timestamp: ", timestamp);
        return "Invalid timestamp";
      }

      const now = new Date();
      const timeDiff = now - timestamp.toDate();
      const seconds = Math.floor(timeDiff / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);

      if (days > 0) return `${days} days ago`;
      if (hours > 0) return `${hours} hours ago`;
      if (minutes > 0) return `${minutes} minutes ago`;
      return "Just now";
    };

    const deleteComment = async (commentId) => {
      try {
        await deleteDoc(doc(db, "Posts", props.docId, "Comments", commentId));
      } catch (error) {
        console.error("Error deleting comment: ", error);
      }
    };

    return {
      comments,
      getDuration,
      isAuthenticated,
      userUid,
      userRole,
      startReply,
      replyTo,
      //   reply, // If you have implemented this method
      deleteComment,
    };
  },
};
</script>

  

<style scoped>
</style>


