<template>
  <div class="container">
    <div class="box">
      <form @submit.prevent="submitForm">
        <div class="field">
          <label class="label">Title</label>
          <input
            class="input"
            type="text"
            v-model="blog.title"
            placeholder="Blog Title"
            required
          />
        </div>
        <div class="field">
          <label class="label">Description</label>
          <input
            class="input"
            type="text"
            v-model="blog.description"
            placeholder="Blog Description"
            required
          />
        </div>
        <div class="field">
          <label class="label">Body</label>
          <QuillEditor
            v-model:content="blog.body"
            content-type="html"
            theme="snow"
          />
          <!-- <textarea
            class="textarea"
            v-model="blog.body"
            placeholder="Blog Body"
            required
          ></textarea> -->
        </div>
        <div class="field">
          <label class="label">Author</label>
          <input
            class="input"
            type="text"
            v-model="blog.author"
            placeholder="Blog Author"
            required
          />
        </div>
        <!-- <div class="field">
          <label class="label">Tags</label>
          <input
            class="input"
            type="text"
            v-model="blog.tags"
            placeholder="Tags (comma separated)"
            required
          />
        </div> -->
        <div class="field">
          <label class="label">Thumbnail Image</label>
          <input type="file" @change="onImageChange" accept="image/*" />
        </div>
        <button class="button is-primary" type="submit">Update</button>
      </form>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { doc, getDoc, serverTimestamp, updateDoc } from "firebase/firestore";
import { db, storage } from "@/firebase/config";
import {
  getDownloadURL,
  getStorage,
  ref as storageRef,
  uploadBytes,
} from "firebase/storage";

import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
export default {
  components: { QuillEditor },
  setup() {
    const route = useRoute();
    const docId = ref(route.params.docId);
    const blog = ref({
      title: "",
      description: "",
      body: "",
      // tags: "",
      lang: "",
      imageUrl: "",
      author: "",
      modeifiedAt: serverTimestamp(),
    });

    const imageFile = ref(null);

    const onImageChange = (event) => {
      imageFile.value = event.target.files[0];
    };
    const router = useRouter();

    const fetchPostData = async () => {
      const postRef = doc(db, "Posts", docId.value);
      const postDoc = await getDoc(postRef);
      if (postDoc.exists()) {
        blog.value = { ...postDoc.data() };
      }
    };

    onMounted(fetchPostData);

    const submitForm = async () => {
      const postRef = doc(db, "Posts", docId.value);
      // Uploading the image to Firebase Storage
      if (imageFile.value) {
        const fileRef = storageRef(storage, `blog-images/${blog.value.title}`);
        await uploadBytes(fileRef, imageFile.value)
          .then((snapshot) => {
            return getDownloadURL(snapshot.ref);
          })
          .then((downloadUrl) => {
            blog.value.imageUrl = downloadUrl;
          });
      }

      // Converting tags to an array
      // blog.value.tags =
      //   typeof blog.value.tags === "string"
      //     ? blog.value.tags.split(",").map((tag) => tag.trim())
      //     : [];

      //console.log(blog.value);
      // Adding the blog post to Firestore
      await updateDoc(postRef, blog.value);

      //console.log("Blog post added:", JSON.stringify(blog.value), postRef.id);
      router.push("/");
      // Resetting the form
      blog.value.title = "";
      blog.value.description = "";
      blog.value.body = "";
      // blog.value.tags = "";
      blog.value.imageUrl = "";
    };

    return {
      blog,
      submitForm,
      onImageChange,
    };
  },
};
</script>

<style>
</style>