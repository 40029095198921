<template>
  <div class="section">
    <!-- Loading Animation -->
    <div v-if="isLoading" class="loading-container">
      <button class="button is-loading">{{ $t("loading") }}</button>
    </div>
    <div class="container mt-5" v-else>
      <!-- Back Button -->
      <router-link to="/" class="button is-black mb-5 is-rounded">
        <span class="icon" v-if="!isRtl">
          <i class="fas fa-arrow-left"></i>
        </span>
        <span>{{ $t("back2HomeBtn") }}</span>
      </router-link>
      <div :class="{ 'is-rtl': isRtl }">
        <!-- Hero Section -->
        <h1 class="title">{{ post.title }}</h1>

        <!-- Body -->
        <div class="content" v-html="post.body"></div>

        <!-- Author and CreatedAt -->
        <div class="columns">
          <div class="column">
            <p>
              <strong>{{ $t("authorLabel") }}</strong> {{ post.author }}
            </p>
          </div>
          <div class="column">
            <p>
              <strong>{{ $t("createdOnLabel") }}</strong>
              {{ formattedCreatedAt }}
            </p>
          </div>
        </div>
      </div>
      <div>
        <!-- Sharing Section with Icons -->
        <div class="buttons">
          <button @click="sharePost" class="button is-rounded is-small">
            <span class="icon" v-if="!isRtl">
              <i class="fas fa-share-alt"></i>
            </span>
            <span>{{ $t("shareBtn") }}</span>
          </button>
          <button
            @click="copyUrlToClipboard"
            class="button is-rounded is-small"
          >
            <span class="icon" v-if="!isRtl">
              <i class="fas fa-link"></i>
            </span>
            <span>{{ $t("copyLinkBtn") }}</span>
          </button>
          <!-- Your ShareNetwork component for Facebook can stay as is -->
        </div>
      </div>

      <!-- Comment Section -->
      <div class="section">
        <div class="container">
          <h3 class="title is-3">{{ $t("commentsLabel") }}</h3>
          <div class="">
            <comments-view :docId="docId" class="mb-5" />
            <div v-if="isAuthenticated">
              <create-comment :docId="docId" />
            </div>
            <div v-else class="box login-signup-box">
              <h1 class="subtitle is-4">{{ $t("signUpAlert") }}</h1>
              <div v-if="displayLogin">
                <login-component />
              </div>
              <div v-else>
                <signup-component />
              </div>
              <a class="mt-3">
                <span v-if="displayLogin" @click="displayLogin = !displayLogin">
                  {{ $t("createAccLabel") }}
                </span>
                <span @click="displayLogin = !displayLogin" v-else>
                  {{ $t("loginAccLabel") }}
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <!-- Latests Posts -->
      <div class="section">
        <div class="container">
          <h3 class="title is-3">{{ $t("latestPostsLabel") }}</h3>
          <div class="columns recent-posts-box">
            <div
              v-for="recentPost in recentPosts"
              :key="recentPost.id"
              class="column is-one-third"
            >
              <div class="box">
                <img :src="recentPost.imageUrl" alt="" />
                <router-link
                  :to="{
                    name: 'PostView',
                    params: { slug: recentPost.title, docId: recentPost.id },
                  }"
                >
                  <h4 class="is-3">{{ recentPost.title }}</h4>
                  <p>
                    <strong>{{ $t("authorLabel") }}</strong>
                    {{ recentPost.author }}
                  </p>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { ref, onMounted, computed, watchEffect, onBeforeUnmount } from "vue";
import { useRoute } from "vue-router";
import { db } from "@/firebase/config";
import {
  doc,
  getDoc,
  collection,
  query,
  orderBy,
  limit,
  getDocs,
  updateDoc,
  increment,
} from "firebase/firestore";
import CommentsView from "@/components/CommentsView.vue";
import CreateComment from "@/components/CreateComment.vue";
import { useStore } from "vuex";
import LoginComponent from "@/components/LoginComponent.vue";
import SignupComponent from "@/components/SignupComponent.vue";
import { getCurrentInstance } from "vue";

export default {
  components: { CommentsView, CreateComment, LoginComponent, SignupComponent },
  setup() {
    const post = ref({});
    const recentPosts = ref([]);
    const route = useRoute();
    const docId = ref(route.params.docId);
    const twitterUrl = ref("");
    const linkedinUrl = ref("");
    const currentUrl = ref("www.thesyrianblog.com" + window.location.pathname);
    const isLoading = ref(true);
    const store = useStore();
    const displayLogin = ref(true);

    const fetchPostData = async () => {
      const postRef = doc(db, "Posts", docId.value);
      const postDoc = await getDoc(postRef);
      post.value = postDoc.exists() ? postDoc.data() : {};

      twitterUrl.value =
        "https://twitter.com/share?url=" +
        currentUrl.value +
        "&text=" +
        post.value.description;
      linkedinUrl.value =
        "https://www.linkedin.com/shareArticle?mini=true&url=" +
        currentUrl.value +
        "&title=" +
        post.value.title +
        "&summary=" +
        post.value.description +
        "&source=" +
        post.value.title;

      // Fetch the latest three posts
      const postsQuery = query(
        collection(db, "Posts"),
        orderBy("createdAt", "desc"),
        limit(3)
      );
      const querySnapshot = await getDocs(postsQuery);
      recentPosts.value = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Increment View Count
      updateDoc(postRef, { viewCount: increment(1) });

      // Attach Scroll Listener to increment Read Count
      window.addEventListener("scroll", handleScroll);

      isLoading.value = false;
    };

    // Remove Scroll Listener on Component Unmount
    onBeforeUnmount(() => {
      window.removeEventListener("scroll", handleScroll);
    });

    // Handle Scroll Event to increment Read Count
    const handleScroll = () => {
      // Check if User has scrolled to the end of the Article
      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - 1000
      ) {
        // Reference to the Post Document
        const postRef = doc(db, "Posts", docId.value);

        // Increment Read Count
        updateDoc(postRef, { readCount: increment(1) });

        // Remove the event listener after incrementing the read count
        window.removeEventListener("scroll", handleScroll);
      }
    };

    const copyUrlToClipboard = () => {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard
          .writeText(currentUrl.value)
          .then(() => {
            console.log("URL copied to clipboard successfully!");
          })
          .catch((err) => {
            console.error("Could not copy URL: ", err);
          });
      } else {
        // Fallback method here (like the textarea one mentioned above)
      }
    };

    const sharePost = () => {
      if (navigator.share) {
        navigator
          .share({
            title: post.value.title,
            text: post.value.description,
            url: currentUrl.value,
          })
          .then(() => {
            console.log("Thanks for sharing!");
          })
          .catch(console.error);
      } else {
        console.log("Web Share API not supported.");
      }
    };

    const updateShareUrls = () => {
      twitterUrl.value =
        "https://twitter.com/share?url=" +
        currentUrl.value +
        "&text=" +
        post.value.description;
      linkedinUrl.value =
        "https://www.linkedin.com/shareArticle?mini=true&url=" +
        currentUrl.value +
        "&title=" +
        post.value.title +
        "&summary=" +
        post.value.description +
        "&source=" +
        post.value.title;
    };

    // onMounted(fetchPostData);

    //console.log(currentUrl);

    watchEffect(() => {
      updateShareUrls(); // Update the share URLs reactively whenever needed
    });

    watchEffect(() => {
      docId.value = route.params.docId;
      fetchPostData(); // Re-fetch data when the docId changes
    });

    const formattedCreatedAt = computed(() => {
      // Assuming createdAt is stored as a Firebase timestamp
      return post.value.createdAt?.toDate().toLocaleDateString();
    });

    const instance = getCurrentInstance(); // Get the current component instance

    const isRtl = computed(() => {
      if (instance && instance.proxy.$i18n) {
        return instance.proxy.$i18n.locale === "en-ar";
      }
    });

    return {
      post,
      formattedCreatedAt,
      recentPosts,
      currentUrl,
      twitterUrl,
      linkedinUrl,
      displayLogin,
      isLoading,
      copyUrlToClipboard,
      sharePost,
      isRtl,
      docId,
      isAuthenticated: computed(() => store.getters.isAuthenticated),
    };
  },
};
</script>
  
<style scoped>
/* Image Style for Main Post */
.post-image-container {
  position: relative;
  width: 100%;
  height: 20vh; /* Adjust as needed */
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.post-image-container img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.post-image-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* 50% black tint */
  z-index: 0;
}

.post-image-container .overlay-text {
  position: relative;
  z-index: 2;
  color: white; /* White text color */
  text-align: center;
}

/* Styles for the latest three posts */
.recent-posts-box .image-container {
  position: relative;
  width: 100%;
  height: 20vh; /* Adjust as needed */
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px; /* Adjust as needed */
}

.recent-posts-box .image-container img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.recent-posts-box .image-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* 50% black tint */
  z-index: 0;
}

.recent-posts-box .overlay-text {
  position: relative;
  z-index: 2;
  color: white; /* White text color */
  text-align: center;
}

.recent-posts-box .box {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Add shadow effect */
  border-radius: 5px; /* Rounded corners */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effect */
}

.recent-posts-box .box:hover {
  transform: translateY(-5px); /* Lift up the box a little */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Enhanced shadow when hovered */
}

.recent-posts-box h4 {
  font-weight: bold; /* Bold titles */
  margin-top: 10px; /* Add some spacing */
}

.recent-posts-box .box a {
  color: inherit; /* Use the parent's color, likely black or dark gray */
  text-decoration: none; /* Remove the underline from links */
}

.recent-posts-box .box a:hover {
  color: inherit; /* Maintain the same color on hover */
  text-decoration: underline; /* Optional: Add underline on hover for a hint that it's a link */
}

.tags {
  margin-bottom: 1rem;
}

.tag {
  margin-right: 0.5rem;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}

.login-signup-box {
  background: #f9f9f9; /* Light Background */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px #0000001a;
  text-align: center;
}

.login-signup-box .buttons button {
  margin-right: 10px;
  background-color: #4caf50; /* Green */
  border: none;
  color: white;
  text-align: center;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

.login-signup-box .buttons button:last-child {
  margin-right: 0;
}
</style>
  