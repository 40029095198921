<template>
  <div class="section">
    <div class="container">
      <div class="box">
        <!-- Profile Section -->
        <div class="columns">
          <div class="column is-2">
            <figure class="image is-128x128">
              <img
                class="is-rounded"
                src="../assets/profilepic.jpg"
                alt="Author"
              />
            </figure>
          </div>
          <div class="column">
            <h1 class="title">{{ $t("authorName") }}</h1>
            <h2 class="subtitle">{{ $t("aboutRole") }}</h2>
          </div>
        </div>

        <!-- About Me Section -->
        <div class="content">
          <h3 class="title is-3">{{ $t("aboutTitle") }}</h3>
          <p>
            {{ $t("aboutText1") }}
            <br />
            {{ $t("aboutText2") }}
          </p>
          <p>{{ $t("aboutText3") }}</p>
        </div>

        <!-- Additional Sections (e.g., Education, Experience) -->
        <!-- You can add more content here to provide a comprehensive view of your background. -->
      </div>
    </div>
  </div>
</template>
