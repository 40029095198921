<template >
  <div :class="{ rtl: isRtl }">
    <Navbar></Navbar>
    <router-view />
    <Footer></Footer>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import { getCurrentInstance } from "vue";
import { computed } from "vue";

export default {
  components: { Navbar, Footer },
  setup() {
    const instance = getCurrentInstance(); // Get the current component instance

    const isRtl = computed(() => {
      if (instance && instance.proxy.$i18n) {
        return instance.proxy.$i18n.locale === "en-ar";
      }
    });

    return { isRtl };
  },
};
</script>

<style>
.rtl {
  direction: rtl;
  text-align: right;
}
</style>
