import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueSocialSharing from 'vue-social-sharing';
import { createI18n } from 'vue-i18n';

// Import your locale files
import enAr from './locales/en-ar.json';
import enEn from './locales/en-en.json';

// Configure VueI18n
const i18n = createI18n({
    locale: 'en-en', // set the initial locale
    fallbackLocale: 'en-en', // set the fallback locale
    messages: {
        'en-ar': enAr,
        'en-en': enEn,
    },
});

store.dispatch('checkAuth').then(() => {
    createApp(App)
        .use(store)
        .use(router)
        .use(VueSocialSharing)
        .use(i18n) // Use VueI18n
        .mount('#app');
});
