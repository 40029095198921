<template>
  <section class="hero is-fullheight has-background-white">
    <div class="hero-body">
      <div class="container">
        <div class="box">
          <div class="columns is-vcentered">
            <!-- Image on the Left -->
            <div class="column is-4">
              <figure class="image">
                <img src="../assets/errorImage.jpeg" alt="404 Error" />
              </figure>
            </div>
            <!-- Text Content on the Right -->
            <div class="column">
              <div class="has-text-centered">
                <h1 class="title is-1 has-text-danger">
                  {{ $t("errorTitle") }}
                </h1>
                <h2 class="subtitle is-3 mt-4">{{ $t("errorAlert") }}</h2>
                <p class="is-5">
                  {{ $t("errorText") }}
                </p>
                <router-link to="/" class="button is-black is-rounded mt-5">{{
                  $t("errorBtn")
                }}</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
  
  <style scoped>
/* Optional: Add custom styling here if needed */
</style>
  