<template>
  <nav
    class="navbar is-white is-fixed-top"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar-brand">
      <a class="navbar-item" href="https://thesyrianblog.com/">
        <!-- <img src="../assets/logo.png" /> -->
        <h1 class="subtitle" style="font-family: 'Pacifico', cursive">
          The Syrian Blog
        </h1>
      </a>

      <a
        role="button"
        class="navbar-burger burger"
        aria-label="menu"
        aria-expanded="false"
        data-target="navbar-menu"
        @click="toggleMenu"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div class="navbar-menu" :class="{ 'is-active': menuActive }">
      <div :class="{ 'navbar-start': isRtl, 'navbar-end': !isRtl }">
        <router-link class="navbar-item" to="/">{{ $t("blog") }}</router-link>
        <router-link class="navbar-item" to="/about"
          >{{ $t("about") }}
        </router-link>
        <router-link class="navbar-item" to="/contact">{{
          $t("contact")
        }}</router-link>

        <!-- <router-link class="navbar-item" to="/account" v-if="isAuthenticated"
          >Account</router-link
        > -->
        <div class="navbar-item">
          <div class="select">
            <select v-model="selectedLanguage" @change="changeLanguage">
              <option value="en-en">English</option>
              <option value="en-ar">عربي</option>
            </select>
          </div>
        </div>

        <div class="navbar-item">
          <button
            class="button is-danger"
            @click="logout"
            v-if="isAuthenticated"
          >
            {{ $t("logout") }}
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>
  
  <script>
import { computed, ref, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const menuActive = ref(false);

    const logout = async () => {
      //console.log("Logging out...");
      await store.dispatch("logout");
      router.push({ name: "home" });
    };

    const toggleMenu = () => {
      menuActive.value = !menuActive.value; // Toggle the menu
    };

    const selectedLanguage = ref("en-en"); // default language

    const instance = getCurrentInstance(); // Get the current component instance

    const changeLanguage = () => {
      if (instance && instance.proxy.$i18n) {
        instance.proxy.$i18n.locale = selectedLanguage.value;
      }
    };

    const isRtl = computed(() => {
      if (instance && instance.proxy.$i18n) {
        return instance.proxy.$i18n.locale === "en-ar";
      }
    });

    return {
      logout,
      isAuthenticated: computed(() => store.getters.isAuthenticated),
      menuActive,
      changeLanguage,
      toggleMenu,
      selectedLanguage,
      isRtl,
    };
  },
};
</script>
  