
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore, serverTimestamp } from 'firebase/firestore'
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyB-AhRErTF209NuToxrKcxTSdLFaKQHtJo",
    authDomain: "the-syrian-blog.firebaseapp.com",
    projectId: "the-syrian-blog",
    storageBucket: "the-syrian-blog.appspot.com",
    messagingSenderId: "641556649724",
    appId: "1:641556649724:web:f91df4bfbd7fbda39ca979",
    measurementId: "G-V3H0TBC9L8"
};

// init firebase Backend
initializeApp(firebaseConfig)

// init services
const auth = getAuth()
const db = getFirestore()
const storage = getStorage()
const timestamp = serverTimestamp

export { db, auth, timestamp, storage }