<template>
  <section class="section">
    <div class="container">
      <div class="box">
        <div class="columns is-vcentered">
          <div class="column">
            <h1 class="title">{{ $t("contactTitle") }}</h1>
            <h2 class="subtitle">{{ $t("contactSubtitle") }}</h2>
            <p>
              <b>{{ $t("emailLabel") }}: </b>info@thesyrianblog.com
            </p>
            <p>
              <b>{{ $t("addressTitle") }}: </b>{{ $t("addressValue") }}
            </p>
            <br />
            <h3 class="subtitle has-text-weight-semibold">
              {{ $t("formTitle") }}
            </h3>
            <form @submit.prevent="submitForm">
              <div class="field">
                <label class="label">{{ $t("nameLabel") }}</label>
                <input
                  class="input is-black"
                  type="text"
                  v-model="form.name"
                  :placeholder="$t('namePlaceholder')"
                  required
                />
              </div>
              <div class="field">
                <label class="label">{{ $t("emailLabel") }}</label>
                <input
                  class="input is-black"
                  type="email"
                  v-model="form.email"
                  :placeholder="$t('emailPlaceholder')"
                  required
                />
              </div>
              <div class="field">
                <label class="label">{{ $t("messageLabel") }}</label>
                <textarea
                  class="textarea is-black"
                  v-model="form.message"
                  :placeholder="$t('messagePlaceholder')"
                  required
                ></textarea>
              </div>
              <button class="button is-black is-rounded" type="submit">
                {{ $t("submitButton") }}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
  
  <script>
import { ref } from "vue";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "@/firebase/config";
import { useRouter } from "vue-router";

export default {
  setup() {
    const form = ref({
      name: "",
      email: "",
      message: "",
      createAt: serverTimestamp(),
    });
    const router = useRouter();

    const submitForm = async () => {
      // Handle the form submission here, e.g., send the data to your server or API
      //console.log("Contact form submitted:", form.value);

      // Adding the contact infp to Firestore
      await addDoc(collection(db, "Contact-Forms"), form.value);

      form.value = {
        name: "",
        email: "",
        message: "",
      };

      router.push("/");
    };

    return {
      form,
      submitForm,
    };
  },
};
</script>
  
  <style scoped>
.title {
  color: #4a4a4a;
}

.subtitle {
  color: #7a7a7a;
}
</style>
  